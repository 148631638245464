import { render, staticRenderFns } from "./SubmissionSummary.vue?vue&type=template&id=0b9eeae2&scoped=true"
import script from "./SubmissionSummary.vue?vue&type=script&lang=js"
export * from "./SubmissionSummary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b9eeae2",
  null
  
)

export default component.exports