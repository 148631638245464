<template>
  <div
    class="content d-flex flex-column align-center justify-center"
    style="row-gap: 20px; height: calc(100vh - 350px)"
  >
    <img :src="sendImage" :width="600" />

    <div class="text-h4" v-visible="!loading">אם הכל מוכן אז...</div>

    <cz-button
      class="mt-6 font-weight-bold text-title"
      :height="60"
      :title="$t('vendor.orderSubmissions.sendSubmissionButtonTitle')"
      x-large
      :elevation="4"
      color="primary"
      :icon-src="mdiSendCheckOutline"
      :icon-size="22"
      @click="$emit('send')"
      :loading="loading"
      :disabled="!canSendSubmission"
    />
  </div>
</template>

<script>
import sendImage from '@/assets/illustrations/target.svg';
import { CzButton } from '@/components';
import { mdiSendCheckOutline } from '@mdi/js';
export default {
  name: 'SubmissionSummary',
  components: {
    CzButton
  },
  props: {
    submission: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    canSendSubmission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mdiSendCheckOutline,
      sendImage
    };
  }
};
</script>

<style lang="scss" scoped></style>
